@import 'styles/typography.less';
@import 'styles/mixins.less';

.UserPersonalInfo {
  &__ImagesWrapper {
    position: relative;
    z-index: 1;
    height: 17.2rem;
    margin-bottom: 3.2rem;
    
    & > div:first-of-type {
      height: 12.8rem;
    }
    
    &_Avatar {
      width: 14.4rem;
      height: 14.4rem;
      margin: -10rem 0 0 1.6rem;
    }
  }
  
  &__Content {
    padding-left: @smallHorizontalSpacingMobile;
    padding-right: @smallHorizontalSpacingMobile;
    
    &_SubTitle {
      .bodyBold();
      
      color: @white;
      margin-bottom: 1.3rem;
    }
  }
}

