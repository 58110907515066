@import 'styles/variables.less';
@import 'styles/mixins.less';

&:global(.is-keyboard-open) {
  .Wrapper {
    &--keyboard {
      margin-bottom: calc(@keyboardHeight - var(--cap-safe-area-bottom));
    }
  }
}

&:global(.is-keyboard-open.android-platform) {
  .Wrapper {
    &--keyboard {
      margin-bottom: @keyboardHeight;
    }
  }
}

.CustomLinksHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.4rem 2.4rem;
  
  &__Title {
    .h4();
    color: @white;
    flex-grow: 1;
    text-align: center;
    
    &--shiftRight {
      padding-left: 7rem;
    }
  }
}

.CustomLinksFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2.4rem calc(2.4rem + @safeAreaBottom);
  
  &__ActionButton {
    width: 16.2rem;
  }
}

.Content {
  margin: 1.6rem 0;
  padding: 0 2.4rem;
}

.CustomLinksDeleteButton {
  color: @alert;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  width: 7rem;
  
  &__Title {
    .commentBold();
  }
}
