@import 'styles/mixins.less';
@import 'styles/typography.less';
@import 'styles/variables.less';

.Item {
  position: relative;
  width: 7.4rem;
  height: 9.4rem;
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  &--small {
    height: 6.6rem;
    width: 6.6rem;
  }

  &__Name {
    .comment();
    .truncateWithEllipsis();
    height: 1.6rem;
    width: 8rem;
    margin-left: -0.2rem;
    display: block;
    min-width: 0;
    color: @grey1;
    text-align: center;
  }
}
