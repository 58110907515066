@import 'styles/typography.less';
@import 'styles/mixins.less';

.EditProfileForm {
  @media @desktop {
    padding-bottom: 10.8rem;
  }
}

.EditProfileFormSubmit {
  display: flex;
  gap: 1.6rem;
  max-width: 69.2rem;
  width: 100%;
  position: fixed;
  bottom: 0;
  padding: 2.4rem 0;
  background: @black;
  
  button {
    width: 100%;
  }
}

&:global(.is-keyboard-open) {
  .Wrapper {
    &--keyboard {
      margin-bottom: calc(@keyboardHeight - var(--cap-safe-area-bottom));
    }
  }
}

&:global(.is-keyboard-open.android-platform) {
  .Wrapper {
    &--keyboard {
      margin-bottom: @keyboardHeight;
    }
  }
}
