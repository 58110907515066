@import 'styles/mixins.less';
@import 'styles/typography.less';
@import 'styles/variables.less';

.SidebarDrawer {
  .sidebar();

  position: fixed;
  z-index: @sidebarZIndex;
  align-items: start;
  width: @sidebarDrawerWidth;
  padding-top: @safeAreaTop;
  background-color: @grey7;
  transform: translateX(-100%);

  &--freezed {
    pointer-events: none;
  }

  &__FollowButton {
    padding: 1.6rem 1.6rem 0;
  }

  &__Content {
    .hideScrollbar();

    width: 100%;
    overflow: auto;
    padding-bottom: @safeAreaBottom;
  }
  
  &__Profile {
    height: 9.8rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1.6rem;
    border-bottom: 0.1rem solid @grey5;
  }

  &__Header {
    .bodyBold();

    padding: 1.6rem 0 1.6rem 1.6rem;
    color: @grey4;
  }

  &__Navigation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 1.6rem;

    button {
      padding: 0 !important;
    }
  }

  &__List {
    padding: 0 @smallHorizontalSpacingMobile @verticalSpacingMobile;
    width: 100%;

    @media @desktop {
      padding: 0 @bigHorizontalSpacingDesktop @bigVerticalSpacingDesktop;
    }
  }

  &__Buttons {
    width: 100%;
    padding: @verticalSpacingMobile @bigHorizontalSpacingMobile;
    border-top: 0.1rem solid @transparentLight3;
  }

  &__Separator {
    display: block;
    width: 100%;
    border-bottom: 0.1rem solid @transparentLight3;
  }

  &__ContactUs {
    padding: 0 1.6rem;
  }

  &__PlayersList {
    .hideScrollbar();
    display: flex;
    flex-direction: row;
    gap: 1.6rem;
    overflow-x: scroll;
    overflow-y: hidden;
    width: 100%;
    padding: 0 1.6rem;
  }
}

.Profile {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  
  &__Info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 0.4rem;
    
    &--username {
      .bodyBold();
      .truncateWithEllipsis();
      color: @white;
      width: 18rem;
    }
    
    &--athlete {
      color: @gold2
    }
    
    &--points {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      background-color: @grey5;
      height: 2.4rem;
      border-radius: 3.5rem;
      padding: 0.4rem 1rem;
    }
    
    &--rating {
      .comment();
      color: @grey1;
      padding-left: 0.6rem;
      display: flex;
      height: 100%;
      align-content: center;
    }
  }
}
