@import 'styles/typography';
@import 'styles/variables';

.Placeholder {
  color: @grey4;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  font-size: 1.5rem;
  line-height: 2rem;
  top: 1.6rem;
  left: 2rem;
  user-select: none;
  white-space: nowrap;
  display: inline-block;
  pointer-events: none;

  &--bottom-sheet-theme {
    left: 1.8rem;
    top: 1.7rem;
  }
}
