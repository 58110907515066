@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/mixins.less';

.SocialLinksContainer {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  height: 4.8rem;
  flex-direction: column;
  padding: 1.6rem 2.4rem;
  gap: 1.6rem;
  margin-top: 1.6rem;
  
  @media @desktop {
    justify-content: flex-end;
    padding: 0;
    margin: 0;
  }
  
  &__Socials {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}
