@import 'styles/variables.less';
@import 'styles/mixins.less';

.ContentWrapper {
  padding: @verticalSpacingMobile 0;
  
  @media @desktop {
    padding: @bigVerticalSpacingDesktop 0;
  }
}

