@import 'styles/variables.less';
@import 'styles/mixins.less';

.EditorBottomSheet {
  max-height: calc(100vh - 3.8rem - @safeAreaTop);
  padding: 1.6rem 1.6rem @safeAreaBottom 1.6rem;
  color: @white;
  transition-duration: 200ms;
  
  @media @desktop {
    padding: 1.6rem;
  }

  &--processing {
    filter: blur(5px); // required for blur
  }

  &--keyboard {
    padding: 1.6rem 1.6rem @keyboardHeight 1.6rem;
  }
}

.Title {
  .h4();
  
  min-height: 4.8rem;
  border-bottom: 0.1rem solid @transparentLight3;
  display: flex;
  align-items: center;
  justify-content: center;
  color: @grey1;
}

&:global(.android-platform) {
  .EditorBottomSheet--keyboard {
    padding: 1.6rem 1.6rem calc(4rem + @keyboardHeight) 1.6rem;
  }
}
