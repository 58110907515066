@import 'styles/variables';
@import 'styles/typography';

.Item {
  display: flex;
  flex-direction: column;
  align-items: center;
  user-select: none;
  position: relative;

  button {
    padding: 0;
  }

  span {
    .comment();

    text-align: center;
    color: @grey3;
  }

  &--active {
    span {
      .commentBold();

      color: @grey1;
    }

    i {
      color: @grey1;
    }
  }
}

.NotificationMarker {
  width: 1.6rem;
  height: 1.6rem;
  position: absolute;
  top: -0.2rem;
  border-radius: 4rem;
  border: 0.2rem solid @black;
  background: @success;
  left: 2.6rem
}
