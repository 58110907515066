@import 'styles/typography.less';
@import 'styles/mixins.less';

.SocialLinks {
  margin-bottom: 2rem;
  padding-left: @smallHorizontalSpacingMobile;
  padding-right: @smallHorizontalSpacingMobile;
  
  &__Content {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    
    & > div {
      margin-bottom: 0;
      
      & > div {
        margin-bottom: 0;
      }
    }
    
    @media @desktop {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
  
  &__SubTitle {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.3rem;
    
    p {
      .bodyBold();
      
      color: @white;
    }
    
    button {
      padding: 0;
    }
  }
}
