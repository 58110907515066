@import 'styles/typography.less';
@import 'styles/mixins.less';

.Biography {
  padding-left: @smallHorizontalSpacingMobile;
  padding-right: @smallHorizontalSpacingMobile;
  
  &__SubTitle {
    .bodyBold();
    
    margin-bottom: 1.3rem;
    color: @white;
  }
}

