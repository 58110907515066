@import 'styles/typography.less';

.CustomLinks {
  margin-bottom: 2rem;
  padding-left: @smallHorizontalSpacingMobile;
  padding-right: @smallHorizontalSpacingMobile;
  
  &__Content {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
  }
  
  &__SubTitle {
    .bodyBold();
    
    color: @white;
    margin-bottom: 1.3rem;
  }
}
