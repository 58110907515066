@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/mixins.less';

.FollowWrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100svh;

  &--has-smart-banner {
    height: calc(100svh - 5.7rem);
  }

  @media @desktop {
    height: calc(100vh - @headerHeight);
  }

  &__Header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0 1.6rem;
    min-height: 5.2rem;

    @media @desktop {
      padding: 0;
    }

    .Title {
      .bodyBold();

      color: @grey1;
      padding-left: 0.8rem;

      @media @desktop {
        .h3();

        padding-left: 1.6rem;
      }
    }

    .Skip {
      .commentBold();

      color: @grey3;
    }

    &--with-skip-button {
      justify-content: space-between;
    }
  }

  &__Details {
    .comment();

    color: @grey3;
    height: 3.2rem;
    padding: 0 1.6rem 1.6rem 1.6rem;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    @media @desktop {
      .body();

      padding: 0;
    }
  }

  &__Filters {
    min-height: 5.2rem;
    display: flex;
    gap: 2.4rem;
    border-top-right-radius: 1.6rem;
    border-top-left-radius: 1.6rem;
    flex-direction: row;
    background-color: @grey8;
    justify-content: stretch;
    padding: 0 1.6rem 0.8rem;
    
    @media @desktop {
      background-color: unset;
      justify-content: flex-start;
      gap: 3.2rem;
      padding: 0 0 0.8rem;
    }

    .Tab {
      .bodyBold();

      width: 100%;
      padding-top: 1.6rem;
      color: @grey1;
      text-align: center;
      justify-content: center;

      @media @desktop {
        width: fit-content;
      }

      &--active {
        border-bottom: 0.1rem solid @normal;
      }
    }
  }

  &__Search {
    padding: 0 1.6rem;
    min-height: 5.6rem;
    background-color: @grey8;
    align-items: center;
    justify-content: center;
    display: flex;

    @media @desktop {
      background-color: unset;
      padding: 0;
    }

    .SearchForm {
      width: 100%;
    }
  }

  &__CollapsedSelection {
    color: @grey1;
    background-color: @grey7;

    @media @desktop {
      border-radius: 0.8rem;
    }

    .Header {
      .bodyBold();

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 3.8rem;
      width: 100%;
      padding: 0 1.6rem;
    }

    .Items {
      .hideScrollbar();

      display: flex;
      flex-direction: row;
      gap: 1.6rem;
      height: 11rem;
      overflow-x: auto;
      padding: 0 1.6rem;
    }
  }

  &__Main {
    .hideScrollbar();

    overflow: hidden;
    display: grid;
    height: 100%;
    grid-template-rows: 1fr;
    padding: 1.6rem 1.6rem @safeAreaBottom;
    background-color: @grey8;

    @media @desktop {
      background-color: unset;
      padding: 1.6rem 0;
    }

    &--with-footer {
      grid-template-rows: 1fr 9.2rem;
    }

    .Results {
      .hideScrollbar();

      overflow-x: hidden;
      overflow-y: auto;
      display: grid;
      gap: 1.6rem;
      height: 100%;
      background-color: @grey8;
      min-height: 100%;

      @media @desktop {
        background-color: unset;
        gap: 2.4rem;
      }

      &--center {
        justify-content: center;
      }

      &__Teams {
        .hideScrollbar();

        display: grid;
        grid-template-columns: repeat(4, minmax(0, 7.4rem));
        justify-content: space-between;
        justify-items: center;
        row-gap: 1.6rem;
        padding-bottom: 1rem;
        grid-auto-rows: 9.4rem;

        @media @desktop {
          grid-template-columns: repeat(9, minmax(0, 7.4rem));
          row-gap: 2.4rem;
        }

        &--with-border {
          border-bottom: 0.1rem solid @transparentLight3;
        }
      }

      &__Players {
        .hideScrollbar();

        display: grid;
        grid-template-columns: repeat(4, minmax(0, 7.4rem));
        justify-content: space-between;
        row-gap: 1.6rem;
        justify-items: center;
        padding-bottom: 1rem;

        @media @desktop {
          grid-template-columns: repeat(9, minmax(0, 7.4rem));
          row-gap: 2.4rem;
        }
      }
    }

    .Follow {
      grid-row: 2;
      height: 4.8rem;
      display: flex;
      margin: 1.2rem 0.8rem 0 0.8rem;
      background-color: @grey8;
      border-top: 0.1rem solid @transparentLight3;

      @media @desktop {
        background-color: unset;
        margin: 1.2rem 0 1.6rem 0;
      }
    }
  }

  .EmptyState {
    display: flex;
    width: 30rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__MainText {
      .bodyBold();

      color: @white;
      height: 2.2rem;
    }

    &__AdditionalText {
      .body();

      color: @grey3;
      height: 2.2rem;
    }

    &__RandomItems {
      .hideScrollbar();

      display: grid;
      padding: 2rem;
      grid-template-columns: repeat(3, minmax(0, 7.4rem));
      justify-content: space-between;
      gap: 1rem;
      justify-items: center;
    }
  }
}
