@import 'styles/typography.less';

.EditProfileHeader {
  h1 {
    .bodyBold();
    margin-right: 3.2rem;
  }
  
  &__Button {
    position: absolute;
    right: 0.8rem;
    top: calc(1.2rem + @safeAreaTop);
  }
}
