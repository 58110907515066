@import 'styles/variables.less';
@import 'styles/typography.less';

.MediaPermission {
  background-color: @grey8;
  padding-bottom: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;

  &__Image {
    margin-bottom: 2.4rem;
    margin-top: 1.6rem;
    width: 18rem;
    height: 18rem;
  }

  &__Title {
    .h3();
    color: @white;
    padding-bottom: 1.6rem;
    text-align: center;
  }

  &__Message, &__UpdateLaterMessage {
    .body();
    color: @grey2;
    text-align: center;
    padding: 0 2.4rem;
    max-width: 35rem;
  }

  &__UpdateLaterMessage {
    padding-top: 1.6rem;
  }
  
  &__Buttons {
    padding: 2.4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 0.5rem;
  }
}
