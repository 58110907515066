@import 'styles/mixins.less';
@import 'styles/variables.less';

.AvatarBorder {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.3rem solid @grey6;
  border-radius: 50%;
  
  &--active {
    border: 0.3rem solid @success;
  }
}

.AvatarBorderInner {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.1rem solid @grey8;
  border-radius: 50%;
}

.AvatarBorderCore {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.2rem solid @black;
  border-radius: 50%;
}

.AvatarImage {
  border-radius: 50%;
  object-fit: cover;
  
  &--size-72 {
    width: 7.2rem;
    height: 7.2rem;
  }
  
  &--size-64 {
    width: 6.4rem;
    height: 6.4rem;
  }
  
  &--size-54 {
    width: 5.4rem;
    height: 5.4rem;
  }
}

