@import 'styles/variables.less';
@import 'styles/typography.less';


.AchievementBadgeComponent {
  width: 8.8rem;
  height: 8.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  
  &__BadgeWrapper, .AchievementBackground {
    width: 12.7rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  &__BadgeCheckbox {
    position: absolute;
    z-index: 3;
    right: -0.2rem;
    top: 1.3rem;
  }
  
  &__BadgeWrapper {
    padding-right: 1rem;
  }
  
  &__BadgeFrame, &__BadgeWrapper, &__Badge, &__BadgeFrameLabel {
    position: absolute;
  }
  
  &__BadgeWrapper {
    height: 100%;
  }
  
  &__Badge {
    object-fit: cover;
  }
  
  &__BadgeFrame, &__Badge {
    z-index: 1;
    width: 100%;
    height: 100%;
  }
  
  &__BucketBadge {
    position: absolute;
    z-index: 2;
    overflow: hidden;
    width: 7.2rem;
    height: 7.2rem;
    border-radius: 50%;
    background: @userAchievementBackgroundGradient;
    
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
      border: 0.2rem solid transparent;
    }
  }
  
  &__BadgeFrameLabel {
    z-index: 3;
    bottom: 0.3rem;
    
    span {
      .commentBold();
      
      position: absolute;
      left: 0;
      right: 0;
      text-align: center;
      white-space: nowrap;
    }
  }
  
}
