@import 'styles/variables.less';
@import 'styles/typography.less';

.EditProfile {

  &__Title {
    .h3();
    margin-bottom: 2.4rem;
  }
}
