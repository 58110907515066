@import 'styles/variables';
@import 'styles/typography';
@import 'styles/mixins';

.PostCardHeader {
  white-space: nowrap;
  padding-bottom: 0.4rem;
  z-index: 1;
  position: relative;

  &--detail-theme,
  &--side-panel-theme {
    display: flex;
    align-items: center;
    gap: 0.8rem;

    .PostCardHeader__Tooltip {
      z-index: 5;
      
      button {
        padding: 0.4rem;
      }
    }

    .PostCardHeaderInfo__Bottom {
      padding-bottom: 0;
    }
  }

  &--detail-theme {
    padding-bottom: 1.2rem;
  }

  &--side-panel-theme {
    padding-bottom: 0.8rem;
  }

  &--bookmark-theme {
    .PostCardHeaderInfo__Username {
      font-size: 1.2rem;
    }
  }

  &--has-team-or-player:not(&--detail-theme) {
    .PostCardHeader__Tooltip {
      z-index: 5;
      margin-top: -0.4rem;
    }
  }

  &__Wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 0.4rem;

    @media @desktop {
      gap: 0.8rem;
    }
  }

  &__Tooltip {
    z-index: 5;
    align-self: start;

    & > div {
      display: flex;
    }
  }
}

.PostCardHeaderInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(100% - 2.9rem);

  &__Top {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    gap: 0.8rem;
  }

  &__Bottom {
    display: flex;
    align-items: center;
    padding-bottom: 0.4rem;
  }
  
  &__Athlete {
    .h4();
    font-weight: 700;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: @gold2;
  }
  
  &__NameSection {
    display: flex;
    align-items: center;
  }

  &__Username {
    .body();
    .truncateWithEllipsis();
    
    color: @grey2;
    max-width: 16rem;
    flex-shrink: 0;

    &--narrow {
      max-width: calc(100% - 19.2rem);
    }

    &--medium {
      max-width: calc(100% - 17rem);
    }

    &--wide {
      max-width: calc(100% - 14rem);
    }

    &--extra-wide {
      max-width: calc(100% - 11rem);
    }
    
    &--defaultColor {
      color: @grey1;
    }
  }

  &__Date {
    .comment();
    padding-left: 0.8rem;
    color: @grey4;
  }
}
