@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/mixins.less';

.PostCard {
  position: relative;
  display: flex;
  align-items: flex-start;
  gap: 0.8rem;
  width: 100%;
  margin: 0 auto;
  padding: 0.8rem 1.6rem 0;
  user-select: auto;
  height: 100%;
  overflow: hidden;
  
  &--with-actions-list {
    padding-bottom: 0.8rem;
  }

  @media @desktop {
    padding: 1.2rem 1.6rem;
    background-color: @grey8;
  }
  
  &--first {
    
    @media @desktop {
      border-top-left-radius: @borderRadius;
      border-top-right-radius: @borderRadius;
    }
  }
  
  .PostCard__Title {
    .bodyBold();
    padding-bottom: 0.4rem;
  }
  
  .PostCard__Content {
    .body()
  }
  
  .PostCard__Interactions {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.4rem;
    color: @grey4;
  }
  
  .PostCard__ActionList {
    .comment();
  }
  
  .PostCard__InteractionFeed {
    .commentBold();
    
    color: @grey3;
  }
  
  &--detail-theme,
  &--side-panel-theme {
    display: block;

    .PostCard__Inner {
      width: 100%;
    }

    .PostCard__Wrapper {
      cursor: auto;
    }

    .PostCard__Title {
      .lineClamp(5);

      user-select: text;
    }
  }

  &--bookmark-theme {
    .PostCard__Avatar {
      top: calc(0.8rem + @safeAreaTop);
    }

    .PostCard__Title {
      .lineClamp(3);
    }

    .PostCard__Content {
      color: @grey2;
    }
  }

  &--single-title {
    .PostCard__Title {
      margin-bottom: 0;
    }
  }

  &--freezed {
    .PostCard__Wrapper {
      pointer-events: none;
    }
  }

  &__Avatar {
    position: relative;
    display: flex;
    height: 100%;
    gap: 1.6rem;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
    padding-top: 1.2rem;
    
    div:first-of-type {
      z-index: 11;
    }
  }

  &__Inner {
    width: calc(100% - 4.8rem);
    height: 100%;
  }

  &__Wrapper {
    display: block;
    color: inherit;
    text-decoration: none;
    cursor: pointer;

    a {
      user-select: auto;
    }
  }

  &__ContentWrapper {
    position: relative;
    overflow-wrap: break-word;
    word-wrap: break-word;
    color: @grey2;
  }

  &__Title {
    .body();

    margin-bottom: 0.4rem;
    color: @white;
    user-select: none;

    &--is-collapsed {
      max-height: calc(@maxTitleLines * 2.2rem);
      overflow: hidden;
    }
  }

  &__Content {
    .body();

    div {
      padding: 0;
    }
  }

  &__Feeds {
    padding: 0.4rem 0 0.8rem;
  }
}
