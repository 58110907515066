@import 'styles/variables.less';
@import 'styles/mixins.less';

.BaseEditor {
  width: 100%;
  position: relative;
  min-height: 4.8rem;
  border-radius: @borderRadius;
  padding: 0;
  border: none;
  
  &--light-theme {
    background-color: transparent;
  }

  &--grey-theme {
    background-color: @transparentLight2;
    border: 0.1rem solid @transparentLight2;
  }
  
  &--active {
    border-color: @normal;
  }

  &__AttachDelete {
    display: none;
    position: absolute;
    right: -0.4rem;
    top: -0.4rem;
    filter: @lightShadow;
    z-index: 1;
  }

  &__AttachVideo {
    position: absolute;
    left: 30%;
    top: 30%;
  }

  &__AttachImagesWrapper {
    .hideScrollbar();

    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
    gap: 0.8rem;
    align-items: center;
    padding: 2.4rem 0;
  }

  &__AttachImageItem {
    position: relative;
    line-height: 0;

    &:hover .BaseEditor__AttachDelete {
      display: block;
    }
  }

  &__Toolbar {
    display: flex;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  
  &__Error {
    .comment();
    
    margin: 0.8rem 0;
    color: @alert;
  }
}

.ImagesLoadingWrapper {
  position: relative;
  flex-shrink: 0;
  width: 8rem;
  height: 8rem;
  border-radius: @borderRadius;
  border: 0.1rem solid @transparentLight3;
  background-color: @grey7;
}

.editor-container {
  background: #fff;
  position: relative;
  cursor: text;
  display: block;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.editor-container.tree-view {
  border-radius: 0;
}

.editor-container.plain-text {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.editor-scroller {
  min-height: 15rem;
  border: 0;
  cursor: text;
  display: flex;
  position: relative;
  outline: 0;
  overflow: auto;
  resize: vertical;
}

.editor {
  flex: auto;
  position: relative;
}

pre {
  line-height: 1.1;
  background: #222;
  color: #fff;
  margin: 0;
  padding: 1rem;
  font-size: 1.2rem;
  overflow: auto;
  max-height: 18rem;
}

.tree-view-output {
  display: block;
  background: #222;
  color: #fff;
  padding: 0;
  font-size: 1.2rem;
  margin: 0.1rem auto 1rem auto;
  max-height: 25rem;
  position: relative;
  overflow: hidden;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

pre::-webkit-scrollbar {
  background: transparent;
  width: 1rem;
}

pre::-webkit-scrollbar-thumb {
  background: #999;
}

.editor-dev-button {
  position: relative;
  display: block;
  width: 4rem;
  height: 4rem;
  font-size: 1.2rem;
  border-radius: 2rem;
  border: none;
  cursor: pointer;
  outline: none;
  box-shadow: 0 0.1rem 1rem rgba(0, 0, 0, 0.3);
  background-color: #444;
}

.ToolbarRow {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.Separator {
  min-width: 0.1rem;
  height: 1.6rem;
  margin: 0 1.6rem;
  background-color: @transparentLight4;
}
