@import 'styles/variables.less';

.SubBottomBar {
  position: absolute;
  right: 1.6rem;
  top: 0;
  z-index: 1;
  width: fit-content;
  height: @bottomBarHeight;
  background-color: @grey7;
  color: @white;
  border-radius: 1.2rem;
  display: inline-block;
  animation: slideInFromRight 0.5s ease-out forwards;
  
  @keyframes slideInFromRight {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  
  &--hide {
    animation: slideOutToRight 0.5s ease-in forwards;
  }
  
  @keyframes slideOutToRight {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(100%);
      display: none;
    }
  }
  
  .SubBottomBar.hide {
    animation: slideOutToRight 0.5s ease-in forwards;
  }
  
  &__ItemsList {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 100%;
    margin: 0;
    gap: 0.6rem;
    padding: 0.6rem 0.4rem;
    list-style: none;
    position: relative;
  }
}
