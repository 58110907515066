@import 'styles/variables.less';
@import 'styles/mixins.less';

.PlayerProfileInfo {
  display: flex;
  flex-direction: column;
  padding: 0 @smallHorizontalSpacingMobile;

  @media @desktop {
    margin-bottom: 2.4rem;
    padding: 0;
  }

  &__Wrapper {
    display: grid;
    grid-template-columns: 1fr 13.2rem 1fr;
    margin-top: -4.8rem;
    justify-content: space-between;
    align-items: end;
    
    @media @desktop {
      margin-top: -8.8rem;
      grid-template-columns: 1fr 15.6rem 1fr;
    }
  }
  
  &__LeftSide {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    height: 8rem;
    overflow: hidden;
    
    @media @desktop {
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      height: 5.6rem;
    }
  }
  
  &__AvatarWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  &__Avatar {
    width: 13.2rem;
    border: solid 0.4rem @black;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    
    @media @desktop {
      margin-bottom: 1.6rem;
      border: solid 0.8rem @black;
      width: 15.2rem;
    }
  }
  
  &__TeamInfo {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
  }
  
  &__Follow {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 1.2rem;
    overflow: hidden;
    height: 8rem;
    
    @media @desktop {
      align-items: center;
      padding: 0;
      height: 5.6rem;
    }
  }

  &__Actions {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
  }
  
  &__Name {
    .truncateWithEllipsis();
    height: 4.4rem;
    width: 100%;
    color: @white;
    align-items: center;
    justify-content: center;
    display: flex;

    @media @desktop {
      margin-bottom: 0.4rem;
    }
  }

  &__TeamName {
    .comment();
    .truncateWithEllipsis();
    
    padding: 0.8rem 0;
    color: @grey2;
    max-width: 9rem;
   
    @media @desktop {
      max-width: 13rem;
    }
  }

  &__TeamButton {
      display: flex;
      align-items: center;
      gap: 0.8rem;
      color: inherit;
  }
  
  &__Emoji {
    position: absolute;
    bottom: 0.8rem;
    left: 0.8rem;
    height: 3.2rem;
    width: 3.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    z-index: 2;
    
    img {
      width: 100%;
      height: 100%;
      margin: 0;
      object-fit: contain;
      object-position: center;
    }
  }
  
  &__Position {
    .comment();
    
    color: @grey2;
  }
  
  &__Jersey {
    .commentBold();
    
    color: @white;
    
    @media @desktop {
      padding-right: 0.8rem;
      margin-right: 0.8rem;
    }
  }
}

.Separator {
  .body();
  display: flex;
  color: @grey3;
  
  @media @desktop {
    padding: 0 1.6rem;
  }
}

