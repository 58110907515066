@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/mixins.less';

.UserPublicStatsContainer {
  display: flex;
  flex-direction: column;
  padding: 2.4rem 0;
  gap: 1.6rem;
  
  @media @desktop {
    padding: 2.4rem 1.6rem;
  }
  
  &__ReputationHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    height: 5rem;
  }
  
  &__ReputationPoints {
    display: grid;
    gap: 0.8rem;
  }
  
  &__Achievements {
    display: flex;
    gap: 1.2rem;
    align-items: center;
    
    &__Streak {
      width: 8.8rem;
      display: flex;
      justify-content: center;
    }
  }
  
  &__ReputationPoints {
    grid-template-columns: repeat(4, 1fr);
  }
  
  &__Title {
    .bodyBold();
    color: @grey1;
  }
  
  &__Action {
    .button();
    
    color: @normal;
  }
  
  &__Section {
    padding-top: 1.2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  &__Header {
    .bodyBold();
    
    color: @white;
    display: flex;
    flex-direction: row;
    height: 3.2rem;
    justify-content: space-between;
    align-items: center;
  }
}

.MoreButton {
  margin-top: 0.8rem;
}
