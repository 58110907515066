@import 'styles/variables.less';
@import 'styles/typography.less';


@extraBronze: #7f5448;
@extraSilver: #7a7c7f;
@extraGold: #87781c;
@extraPlatinum: #4c648c;

@platinumProgress: #534e26;
@goldProgress: #606366;
@silverProgress: #644840;

@bronzeBorder: #BD7159;
@silverBorder: #A1A2A3;
@goldBorder: #F4A200;

.AchievementCard {
  width: 100%;
  height: 10.4rem;
  border-radius: @borderRadius;
  border: 0.2rem solid transparent;
  display: flex;
  overflow: hidden;
  position: relative;
  
  .AchievementBackground {
    min-width: 9rem;
    
    @media @mobile {
      min-width: 10rem;
    }
    
    @media @desktop {
      min-width: 11rem;
    }
  }
  
  &__BadgeWrapper, &__Streak, .AchievementBackground {
    width: 10rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    
    @media @mobile {
      width: 12rem;
    }
    
    @media @desktop {
      width: 12.7rem;
    }
  }
  
  .AchievementBackground {
    position: relative;

    &:after {
      position: absolute;
      content: '';
      width: 2.5rem;
      height: 110%;
      top: -1rem;
      bottom: 0;
      right: 0.2rem;
      transform: rotate(12.5deg) translateX(68%);
    }
  }
  
  .AchievementContent {
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 2.7rem;
    
    @media @mobile {
      padding-left: 3.4rem;
    }
    
    @media @desktop {
      padding-left: 4rem;
    }
    
    &__Info {
      max-width: 12.5rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 0.3rem;
      
      @media @desktop {
        max-width: 16.6rem;
      }
    }
    
    &__Title {
      .bodyBold();
      
      white-space: nowrap;
      color: @white;
    }
    
    &__Description {
      .comment();
      
      color: @grey1;
    }
    
    &__Points {
      .commentBold();
      
      color: @grey1;
    }
    
    &__Progress {
      margin-left: auto;
      margin-right: 1rem;
      
      @media @mobile {
        margin-right: 1.5rem;
      }
      
      @media @desktop {
        margin-right: 2rem;
      }
      
      &__Days {
        width: 6.4rem;
        height: 6.4rem;
        border: 0.4rem solid @grey6;
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        h4 {
          .h4();
        }
        
        span {
          .comment();
          
          text-transform: uppercase;
        }
      }
    }
  }
  
  &__streak {
    border-color: @grey6;
    
    .AchievementBackground, .AchievementBackground:after {
      background: @grey8;
    }
  }
  
  &__platinum {
    .AchievementBackground, .AchievementBackground:after{
      background: @platinumProgress;
    }
    
    .AchievementCard__BucketBadge img {
      border-color: @goldBorder;
    }
  }

  &__platinum&__completed {
    .AchievementBackground, .AchievementBackground:after{
      background: @extraPlatinum;
    }
    .AchievementContent {
      background: linear-gradient(95deg, #46608B 1.27%, rgba(70, 96, 139, 0.00) 83.55%), @grey7;
    }
    .AchievementCard__BucketBadge img {
      border-color: transparent;
    }
  }
  
  &__gold {
    .AchievementBackground, .AchievementBackground:after{
      background: @goldProgress;
    }
    
    .AchievementCard__BucketBadge img {
      border-color: @silverBorder;
    }
  }
  
  &__gold&__completed {
    .AchievementBackground, .AchievementBackground:after{
      background: @extraGold;
    }
    .AchievementContent {
      background: linear-gradient(95deg, rgba(255, 215, 0, 0.40) 1.27%, rgba(255, 215, 0, 0.00) 83.55%), @grey7;
    }
    .AchievementCard__BucketBadge img {
      border-color: @goldBorder;
    }
  }
  
  &__silver {
    .AchievementBackground, .AchievementBackground:after{
      background: @silverProgress;
    }
    
    .AchievementCard__BucketBadge img {
      border-color: @bronzeBorder;
    }
  }
  
  &__silver&__completed {
    .AchievementBackground, .AchievementBackground:after{
      background: @extraSilver;
    }
    .AchievementContent {
      background: linear-gradient(90deg, rgba(181, 182, 183, 0.40) 0%, rgba(181, 182, 183, 0.00) 100%), @grey7;
    }
    .AchievementCard__BucketBadge img {
      border-color: @silverBorder;
    }
  }
  
  &__bronze {
    .AchievementBackground, .AchievementBackground:after{
      background: @grey8;
    }
  }
  
  &__bronze&__completed {
    .AchievementBackground, .AchievementBackground:after{
      background: @extraBronze;
    }
    .AchievementContent {
      background: linear-gradient(90deg, rgba(189, 113, 89, 0.40) 0%, rgba(189, 113, 89, 0.00) 100%), @grey7;
    }
    .AchievementCard__BucketBadge img {
      border-color: @bronzeBorder;
    }
  }
  
  &__bronze, &__silver, &__gold, &__platinum {
    .AchievementContent {
      background: @grey7;
    }
  }
}


