@import 'styles/variables.less';

@leaderboardFiltersHeight: 11.6rem;

.Leaderboard {
  width: 100%;
  height: calc(100 * var(--vh) - @headerHeight - @bottomBarHeight - @leaderboardFiltersHeight - @safeAreaTop - @safeAreaBottom);
  
  &--mobileWeb {
    height: calc(100 * var(--vh) - @headerHeight - @bottomBarHeight - @leaderboardFiltersHeight - @smallDeeplinkBannerHeight);
  }
}
